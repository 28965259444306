/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-variant-ligatures: inherit;
  font-variant-caps: inherit;
  font-variant-numeric: inherit;
  font-variant-east-asian: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  vertical-align: baseline;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Custom */
*, *:after, *:before {
  box-sizing: border-box; }

input, textarea, select {
  border: none;
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

a, input, textarea, button, select,
a:focus, input:focus, textarea:focus, button:focus, select:focus,
a:active, input:active, textarea:active, button:active, select:active {
  outline: none; }

textarea {
  resize: none; }

img {
  display: block;
  max-width: 100%;
  border-style: none; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    color: inherit;
    text-decoration: none; }
  aactive {
    color: inherit; }

[hidden] {
  display: none; }

/*IBMPlexSans-Regular*/
@font-face {
  font-family: 'IBMPlexSans';
  src: url("../fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.eot"), url("../fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.svg"), url("../fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.woff") format("woff"), url("../fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/*IBMPlexSans-Regular*/
/*IBMPlexSans-Medium*/
@font-face {
  font-family: 'IBMPlexSans';
  src: url("../fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.eot"), url("../fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.svg"), url("../fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.woff") format("woff"), url("../fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

/*IBMPlexSans-Medium*/
/*IBMPlexSans-Bold*/
@font-face {
  font-family: 'IBMPlexSans';
  src: url("../fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.eot"), url("../fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.svg"), url("../fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.woff") format("woff"), url("../fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

/*IBMPlexSans-Bold*/
/*IBMPlexSans-SemiBold*/
@font-face {
  font-family: 'IBMPlexSans';
  src: url("../fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.eot"), url("../fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.svg"), url("../fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.woff") format("woff"), url("../fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

/*IBMPlexSans-SemiBold*/
html,
body {
  font-size: 16px;
  color: #FFFFFF;
  background: #074753;
  font-weight: 300;
  line-height: 1;
  font-family: "IBMPlexSans", sans-serif; }

main {
  overflow-x: hidden; }

*::-webkit-input-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

*::-moz-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

*:-ms-input-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

*:-moz-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

* {
  outline: 0; }

main {
  flex-grow: 1; }

svg, svg path, a, button {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

a:visited {
  color: inherit; }

img {
  display: inline-block;
  width: auto;
  max-width: 100%;
  height: auto; }

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1170px;
  position: relative;
  z-index: 2; }

@media (min-width: 1200px) {
  .container {
    max-width: 942px; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .container {
    max-width: 770px; } }

@media (max-width: 959px) and (min-width: 642px) {
  .container {
    max-width: 640px;
    padding: 0 36px; } }

@media (max-width: 641px) {
  .container {
    max-width: 100%;
    padding: 0 24px; } }

.row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap; }

@media (max-width: 959px) {
  .row {
    margin-left: -36px;
    margin-right: -36px; } }

@media (max-width: 641px) {
  .row {
    margin-left: -24px;
    margin-right: -24px; } }

.offset-col {
  padding: 0 15px;
  margin-right: -228px;
  margin-left: 230px;
  width: calc(100% + 230px); }
  .offset-col.full {
    margin-left: 0 !important; }

@media (max-width: 1400px) {
  .offset-col {
    margin-right: calc((((100vw - 100% + 30px) / 2) - 36px) * -1);
    width: calc(100% + calc((((100vw - 100% + 30px) / 2) - 36px))); } }

@media (max-width: 1199px) and (min-width: 960px) {
  .offset-col {
    margin-left: 148px;
    width: calc(100% + 148px); } }

@media (max-width: 959px) {
  .offset-col {
    margin-left: 0;
    margin-right: 0;
    padding: 0 36px;
    width: 100%; } }

@media (max-width: 641px) {
  .offset-col {
    padding: 0 24px; } }

.ttl {
  font-weight: 600;
  font-size: 100px;
  line-height: 100%; }

@media (max-width: 1199px) {
  .ttl {
    font-size: 72px;
    line-height: 100%; } }

@media (max-width: 959px) {
  .ttl {
    font-size: 48px;
    line-height: 62px; } }

@media (max-width: 641px) {
  .ttl {
    font-size: 36px;
    line-height: 47px; } }

.subttl {
  font-weight: 600;
  font-size: 48px;
  line-height: 62px; }

@media (max-width: 959px) {
  .subttl {
    font-size: 36px;
    line-height: 47px; } }

@media (max-width: 641px) {
  .subttl {
    font-size: 28px;
    line-height: 36px; } }

.content-box {
  line-height: 1.4; }
  .content-box h2 {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 28px; }
  .content-box p {
    margin-bottom: 20px; }
  .content-box img {
    margin-bottom: 20px; }
  .content-box b, .content-box strong {
    font-weight: 700; }
  .content-box i, .content-box em {
    font-style: italic; }
  .content-box h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px; }
  .content-box h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px; }
  .content-box ul {
    font-size: 20px;
    line-height: 140%;
    list-style: none;
    padding: 0;
    margin: 0;
    color: #323232; }
    .content-box ul li {
      padding-left: 30px;
      margin-bottom: 22px;
      position: relative; }
      .content-box ul li:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #39B54A;
        display: block;
        position: absolute;
        left: 0;
        top: 10px; }
  .content-box ol {
    list-style: decimal;
    padding-left: 20px;
    margin-bottom: 15px; }
  .content-box blockquote {
    border: dotted #666 1px;
    border-left: solid #39B54A 4px;
    margin: 10px 40px;
    padding: 15px;
    color: #333;
    font-style: italic;
    font-size: 14px;
    background: #FFFFFF; }
    .content-box blockquote .quote a {
      color: #074753;
      font-size: 12px; }
    .content-box blockquote .quote ul {
      list-style: none; }
  .content-box table thead th {
    border-bottom: 2px solid #39B54A;
    padding: 3px 10px; }
  .content-box table tbody td {
    border: 1px solid #ccc;
    padding: 3px 10px; }

a:hover {
  color: #39B54A; }

.btn {
  border: 2px solid #39B54A;
  display: inline-block;
  padding: 18px 37px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 50px;
  background: none;
  color: #074753;
  text-align: center;
  position: relative;
  overflow: hidden; }
  .btn.txt_white {
    color: #FFFFFF; }
  .btn:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #39B54A;
    display: block;
    transition: 0.3s; }
  .btn span {
    position: relative;
    z-index: 2; }
  .btn:hover {
    color: #FFFFFF; }
    .btn:hover:before {
      left: 0;
      transition: 0.3s; }

.slick-arrow {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #39B54A;
  cursor: pointer;
  z-index: 3;
  opacity: 1;
  transition: 0.3s; }
  .slick-arrow.arrow-next {
    left: 80px; }
  .slick-arrow:hover {
    opacity: 0.7;
    transition: 0.3s; }

@media (max-width: 641px) {
  .slick-arrow.arrow-next {
    left: auto;
    right: 0; } }

/*header*/
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(7, 71, 83, 0.75), rgba(7, 71, 83, 0));
  display: block;
  z-index: 10;
  padding: 26px 36px; }
  .header .container {
    position: relative;
    z-index: 2; }

.h-logo {
  position: absolute;
  z-index: 3; }

.h-info {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap; }

.h-phone {
  margin-left: 40px; }

.h-lang .lang {
  margin-left: 20px; }
  .h-lang .lang:first-child {
    margin-left: 0; }
  .h-lang .lang.cur {
    font-weight: 600; }

.h-menu_btn {
  top: 36px;
  right: 36px;
  position: absolute;
  z-index: 3;
  display: block; }
  .h-menu_btn button {
    background: none;
    border: none;
    width: 60px;
    cursor: pointer;
    padding: 0; }
    .h-menu_btn button span {
      width: 100%;
      height: 2px;
      background: #fff;
      margin: 11px 0;
      display: block; }
      .h-menu_btn button span:first-child {
        margin-top: 0; }
      .h-menu_btn button span:last-child {
        margin-bottom: 0; }
    .h-menu_btn button.active span:first-child {
      transition: 0.3s;
      transform: rotate(-25deg) translate(-5px, 14px); }
    .h-menu_btn button.active span:last-child {
      transition: 0.3s;
      transform: rotate(25deg) translate(-2px, -12px); }
    .h-menu_btn button.active span:nth-child(2) {
      width: 0;
      transition: 0.3s;
      transform: translateX(50%); }

.h-menu {
  position: absolute;
  z-index: -9999;
  opacity: 0;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 100px;
  background: #FFFFFF;
  pointer-events: none;
  transition: opacity 0.3s; }
  .h-menu.active {
    z-index: 1;
    opacity: 1;
    transition: opacity 0.3s;
    pointer-events: all; }
  .h-menu ul li {
    margin-bottom: 36px; }
    .h-menu ul li a {
      font-weight: 600;
      font-size: 36px;
      line-height: 120%;
      color: #074753; }

.header.active .h-logo svg path {
  fill: #074753; }

.header.active .h-phone a {
  color: #074753; }

.header.active .h-lang .lang {
  color: #074753; }

.header.active .h-menu_btn button span {
  background: #074753; }

/*header*/
.video-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center; }

/*first*/
.first {
  padding-top: 139px;
  background: url("../img/first-back.png") no-repeat center top;
  padding-bottom: 164px;
  position: relative; }
  .first:before {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(100% + 73px);
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    background: url("../img/tok_fon.gif") left bottom;
    background-repeat: repeat-x; }
  .first .container {
    position: relative;
    z-index: 3; }
  .first-block {
    position: absolute;
    left: 0;
    box-shadow: 0px 10px 50px -10px rgba(7, 71, 83, 0.38);
    background: url("../img/first-block.png") no-repeat 100% 84%, #fff;
    width: 379px;
    padding: 31px 35px;
    padding-right: 135px;
    border-radius: 6px;
    top: 154px;
    z-index: 4;
    color: #074753; }
    .first-block-ttl {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 27px; }
    .first-block-more a {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #39B54A; }
  .first-bl {
    color: #074753; }
    .first-bl-ttl {
      margin-bottom: 60px;
      color: #074753; }
    .first-bl-txt {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 76px; }

/*first*/
/*work*/
.work {
  padding-top: 185px;
  padding-bottom: 70px; }
  .work-in {
    margin: 0 auto;
    position: relative;
    max-width: 1400px; }
  .work-back {
    position: absolute;
    z-index: 1;
    background: url("../img/work-back.png") no-repeat center center;
    -webkit-background-size: 1312px 1312px;
    background-size: 1312px 1312px;
    width: 1312px;
    height: 1312px;
    display: block; }
    .work-back.back1 {
      background-image: url("../img/work-back.png");
      top: -100px;
      left: -314px; }
    .work-back.back2 {
      background-image: url("../img/work-back2.png");
      top: 43px;
      right: -398px; }
    .work-back.back3 {
      background-image: url("../img/work-back3.png");
      top: -436px;
      left: -353px; }
  .work-ttl {
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 65px; }
  .work-power {
    font-weight: 600;
    font-size: 48px;
    line-height: 100%;
    margin-bottom: 63px; }
  .work-txt {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 74px; }
  .work-img {
    overflow: hidden;
    border-radius: 6px;
    max-width: 912px;
    margin-bottom: 69px; }
  .work-txt2 {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 75px; }
  .work-info-bl {
    padding: 0 15px;
    width: 50%;
    margin-bottom: 72px; }
    .work-info-bl-img {
      margin-bottom: 29px; }
    .work-info-bl-txt {
      font-size: 18px;
      line-height: 23px; }

/*work*/
/*goal*/
.goals {
  padding-top: 57px;
  padding-bottom: 64px;
  background: url("../img/goal-back.jpg") no-repeat center top;
  -webkit-background-size: cover;
  background-size: cover; }
  .goals-forsl {
    overflow: hidden; }
  .goals-ttl {
    margin-bottom: 66px; }
  .goals-slider {
    padding-top: 140px;
    max-width: 453px;
    margin-bottom: 68px; }
    .goals-slider .slick-list.draggable {
      overflow: visible; }
  .goals-slide-bl {
    background: #FFFFFF;
    color: #074753;
    padding: 32px 37px;
    border-radius: 6px;
    margin-right: 4px; }
    .goals-slide-bl-num {
      font-weight: 600;
      font-size: 48px;
      line-height: 62px;
      margin-bottom: 16px;
      color: #39B54A; }
    .goals-slide-bl-txt {
      font-size: 18px;
      line-height: 23px;
      overflow: hidden;
      height: 94px; }
  .goals-txt {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 75px; }
  .goals-descr {
    margin-bottom: 56px; }
  .goals-subttl {
    margin-bottom: 66px; }
  .goals-gallery-bl {
    position: relative; }
  .goals-gallery-slide-img {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 6px; }
  .goals-gallery-slide-txt {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
    padding: 36px;
    font-size: 18px;
    line-height: 23px;
    background: #39B54A;
    width: 684px;
    border-radius: 6px; }
  .goals-gallery .slick-arrow {
    top: 50%;
    margin-top: -30px;
    left: 36px; }
    .goals-gallery .slick-arrow.arrow-next {
      left: auto;
      right: 36px; }
  .goals-in {
    margin: 0 auto;
    position: relative;
    max-width: 1400px; }
  .goals-back {
    position: absolute;
    z-index: 1;
    background: url("../img/work-back.png") no-repeat center center;
    -webkit-background-size: 1312px 1312px;
    background-size: 1312px 1312px;
    width: 1312px;
    height: 1312px;
    display: block; }
    .goals-back.back1 {
      background-image: url("../img/work-back.png");
      top: -483px;
      left: -314px; }
    .goals-back.back2 {
      background-image: url("../img/work-back2.png");
      top: -172px;
      right: -398px; }

/*goal*/
/*coef*/
.coef {
  padding-top: 160px;
  padding-bottom: 30px; }
  .coef-ttl {
    margin-bottom: 56px; }
  .coef-txt {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 73px; }
  .coef-row {
    margin: 0 -1px 79px; }
  .coef-col {
    margin-bottom: 3px;
    padding: 0 1px;
    width: 50%; }
    .coef-col.full {
      width: 100%; }
  .coef-bl {
    background: #FFFFFF;
    color: #074753;
    padding: 36px 37px;
    border-radius: 6px;
    height: 100%; }
    .coef-bl-num {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #39B54A;
      margin-bottom: 22px; }
    .coef-bl-txt {
      font-size: 18px;
      line-height: 23px; }

/*coef*/
/*compar*/
.compar {
  padding-top: 28px;
  padding-bottom: 75px;
  overflow: hidden;
  background: url("../img/compar-back.jpg") no-repeat center top;
  -webkit-background-size: cover;
  background-size: cover; }
  .compar-ttl {
    margin-bottom: 59px; }
  .compar-subttl {
    margin-bottom: 55px; }
  .compar-tabs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 49px; }
  .compar-tab {
    border: 2px solid rgba(255, 255, 255, 0.3);
    display: inline-block;
    padding: 18px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: .05em;
    text-transform: uppercase;
    border-radius: 50px;
    background: 0 0;
    margin-right: 22px;
    color: #39B54A;
    margin-bottom: 18px; }
    .compar-tab.active {
      color: #FFFFFF;
      background: #39B54A;
      border-color: #39B54A; }
  .compar-txt {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 54px; }
  .compar-txt2 {
    font-size: 24px;
    line-height: 31px; }
  .compar-slider {
    position: static; }
    .compar-slider_wrap {
      border-radius: 6px;
      background: #FFFFFF;
      padding: 37px 37px 75px;
      position: relative;
      margin-bottom: 65px; }
    .compar-slider .slick-arrow {
      top: auto;
      bottom: 37px;
      width: auto;
      height: auto;
      background: #FFFFFF;
      left: 35px; }
      .compar-slider .slick-arrow svg path {
        stroke: #39B54A; }
      .compar-slider .slick-arrow.arrow-next {
        left: 91px; }
  .compar-slide-bl {
    background: #FFFFFF;
    color: #000000; }
    .compar-slide-bl-num {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #39B54A;
      margin-bottom: 22px; }
    .compar-slide-bl-txt {
      font-size: 18px;
      line-height: 23px;
      min-height: 78px; }
  .compar-cont.hid {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    z-index: -9999; }

/*compar*/
/*serv*/
.serv {
  padding-top: 43px;
  padding-bottom: 42px; }
  .serv-in {
    margin: 0 auto;
    position: relative;
    max-width: 1400px; }
  .serv-back {
    position: absolute;
    z-index: 1;
    background: url("../img/work-back.png") no-repeat center center;
    -webkit-background-size: 1312px 1312px;
    background-size: 1312px 1312px;
    width: 1312px;
    height: 1312px;
    display: block; }
    .serv-back.back1 {
      background-image: url("../img/work-back.png");
      top: -318px;
      left: -505px; }
    .serv-back.back2 {
      background-image: url("../img/work-back2.png");
      top: 175px;
      right: -527px; }
  .serv-ttl {
    margin-bottom: 68px; }
  .serv-blocks {
    margin: 0 -1px 65px;
    display: flex;
    flex-wrap: wrap; }
  .serv-bl {
    border: 2px solid #6d8f8d;
    width: 455px;
    margin: 0px 1px 2px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 379px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    background-size: cover; }
    .serv-bl-ttl {
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #FFFFFF;
      position: relative;
      z-index: 2; }
    .serv-bl-txt {
      color: #FFFFFF;
      font-size: 18px;
      line-height: 23px;
      position: relative;
      z-index: 2; }
    .serv-bl:nth-child(2), .serv-bl:nth-child(3) {
      width: 683px; }
    .serv-bl:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: #074753;
      opacity: 0.7; }
  .serv-info {
    margin-top: 69px;
    border-top: 2px solid rgba(255, 255, 255, 0.1); }
    .serv-info-bl {
      padding-top: 31px;
      padding-left: 35px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-right: 36px;
      padding-bottom: 42px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.1); }
      .serv-info-bl-ttl {
        font-size: 24px;
        line-height: 31px;
        padding-right: 15px;
        flex: 1; }
      .serv-info-bl-csl button {
        background: #39B54A;
        border-radius: 50%;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        transform: rotate(-45deg);
        transition: 0.3s;
        cursor: pointer; }
        .serv-info-bl-csl button.active {
          transform: rotate(0deg);
          transition: 0.3s; }
      .serv-info-bl-txt {
        padding-top: 29px; }
        .serv-info-bl-txt * {
          color: #FFFFFF;
          font-size: 18px;
          line-height: 23px;
          margin-bottom: 23px; }
          .serv-info-bl-txt *:last-child {
            margin-bottom: 0; }
      .serv-info-bl-row {
        margin: 0 -15px -42px;
        padding-top: 30px;
        display: flex;
        flex-wrap: wrap; }
        .serv-info-bl-row-block {
          width: 50%;
          padding: 0 15px;
          margin-bottom: 42px; }
          .serv-info-bl-row-block-num {
            font-weight: 600;
            font-size: 18px;
            line-height: 23px;
            color: #39B54A;
            margin-bottom: 22px; }
          .serv-info-bl-row-block-txt {
            color: #FFFFFF;
            font-size: 18px;
            line-height: 23px; }

/*serv*/
/*info*/
.info {
  padding-top: 38px;
  padding-bottom: 25px;
  overflow: hidden; }
  .info-slider {
    padding-top: 140px;
    overflow: visible; }
    .info-slider .slick-slide {
      margin-right: 10px; }
    .info-slider .slick-list {
      overflow: visible; }
  .info-slide-bl {
    border-radius: 6px;
    background: #FFFFFF;
    padding: 31px 37px;
    color: #021418;
    overflow: hidden; }
    .info-slide-bl-ttl {
      margin-bottom: 55px; }
    .info-slide-bl-txt {
      font-size: 24px;
      line-height: 31px; }
    .info-slide-bl-img {
      margin: 0 -37px -31px; }
    .info-slide-bl-row {
      padding-top: 73px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px 0; }
    .info-slide-bl-col {
      width: 50%;
      padding: 0 15px;
      margin-bottom: 20px; }
      .info-slide-bl-col-img {
        margin-bottom: 29px; }
      .info-slide-bl-col-txt {
        font-size: 18px;
        line-height: 23px; }
    .info-slide-bl-txt2 {
      font-size: 18px;
      line-height: 23px;
      margin-top: 46px; }
      .info-slide-bl-txt2 p {
        margin-bottom: 23px; }
    .info-slide-bl-cls {
      margin-top: 75px; }
      .info-slide-bl-cls button {
        background: none;
        border: 0;
        padding: 0;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #39B54A;
        cursor: pointer; }
        .info-slide-bl-cls button .plus {
          background: #39B54A;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          margin-left: 12px;
          border-radius: 50%;
          transition: 0.3s;
          transform: rotate(-45deg); }
        .info-slide-bl-cls button.active .plus {
          transition: 0.3s;
          transform: rotate(0deg); }
    .info-slide-bl-img {
      margin-top: 67px; }

/*info*/
/*cf*/
.cf {
  padding-top: 59px;
  padding-bottom: 50px;
  position: relative;
  z-index: 2; }
  .cf-in {
    margin: 0 auto;
    position: relative;
    max-width: 1400px; }
  .cf-back {
    position: absolute;
    z-index: 1;
    background: url("../img/work-back.png") no-repeat center center;
    -webkit-background-size: 1312px 1312px;
    background-size: 1312px 1312px;
    width: 1312px;
    height: 1312px;
    display: block; }
    .cf-back.back1 {
      background-image: url("../img/work-back.png");
      top: -213px;
      left: -325px;
      background-size: 885px 885px;
      width: 885px;
      height: 885px; }
    .cf-back.back2 {
      background-image: url("../img/work-back2.png");
      top: -175px;
      right: -427px; }
  .cf-wrap {
    border-radius: 6px;
    background: url("../img/form-back.jpg") no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    padding: 80px 81px; }
  .cf-ttl {
    margin-bottom: 12px; }
  .cf-txt {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 73px; }
  .cf-form-input span {
    margin-bottom: 21px;
    display: block; }
  .cf-form-input input {
    display: block;
    width: 100%;
    border-radius: 2px;
    padding: 18px 17px; }
  .cf-form-input-check {
    padding-top: 8px;
    max-width: 590px; }
    .cf-form-input-check .wpcf7-list-item-label {
      cursor: pointer;
      padding-left: 25px;
      font-size: 14px;
      margin-bottom: 43px;
      display: inline-block;
      position: relative;
      letter-spacing: 0.05em;
      line-height: 1.5; }
      .cf-form-input-check .wpcf7-list-item-label:before {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 0;
        left: 0;
        border: 2px solid #39B54A;
        border-radius: 4px; }
      .cf-form-input-check .wpcf7-list-item-label a {
        text-decoration: underline; }
    .cf-form-input-check input[type="checkbox"]:checked + .wpcf7-list-item-label:before {
      background-color: #FFFFFF;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L3 7 1 4' stroke='%2339B54A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-size: 6px 6px;
      background-position: 2px 3px; }
  .cf-form .wpcf7-not-valid-tip {
    background: #dc3232;
    color: #fff;
    padding: 3px 5px;
    display: inline-block; }

/*cf*/
/*eco*/
.eco {
  padding-top: 75px;
  position: relative; }
  .eco .back {
    position: absolute;
    z-index: 1;
    top: -370px;
    left: 0;
    background: url("../img/eco-back.png") no-repeat 0% 0%, #FFFFFF;
    height: calc(100% + 372px);
    width: 100%; }
    .eco .back:before {
      content: '';
      width: 100%;
      height: 50px;
      display: block;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 2;
      background: #074753;
      background: -webkit-linear-gradient(top, #074753, rgba(255, 255, 255, 0));
      background: linear-gradient(to bottom, #074753, rgba(255, 255, 255, 0)); }
  .eco-ttl {
    color: #074753; }
  .eco-row {
    margin: 0 -1px 79px; }
  .eco-col {
    margin-bottom: 3px;
    padding: 0 1px;
    width: 50%; }
    .eco-col.full {
      width: 100%; }
  .eco-bl {
    background: #FFFFFF;
    color: #074753;
    padding: 36px 37px;
    border-radius: 6px;
    height: 100%;
    border: 1px solid #E6E6E6; }
    .eco-bl-num {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #39B54A;
      margin-bottom: 22px; }
    .eco-bl-txt {
      font-size: 18px;
      line-height: 23px; }
  .eco-subttl {
    margin-top: 127px;
    font-weight: 600;
    font-size: 48px;
    line-height: 115%;
    color: #021418; }
  .eco-txt {
    font-size: 24px;
    line-height: 31px;
    color: #021418;
    margin-top: 58px; }
  .eco-txt2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #39B54A;
    margin-top: 62px;
    margin-bottom: 73px; }

/*eco*/
/*contacts*/
.contacts {
  background: url("../img/cont-back.png") no-repeat 50% 0%;
  background-color: #074753;
  background-size: cover;
  padding-top: 554px;
  position: relative; }
  .contacts:before {
    content: '';
    width: 100%;
    height: 50px;
    display: block;
    top: 0px;
    left: 0;
    position: absolute;
    z-index: 2;
    background: #FFFFFF;
    background: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0)); }
  .contacts-wrap {
    padding: 81px 81px 11px;
    background: #FFFFFF;
    border-radius: 4px 4px 0 0; }
  .contacts-ttl {
    color: #074753;
    margin-bottom: 57px; }
  .contacts-bl {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 70px; }
  .contacts-top {
    font-size: 18px;
    line-height: 23px;
    color: #39B54A;
    margin-bottom: 23px; }
  .contacts-btm {
    font-size: 18px;
    line-height: 23px;
    color: #021418; }

/*contacts*/
/*header*/
@media (max-width: 959px) {
  .header {
    display: flex;
    flex-wrap: wrap; }
    .header .container {
      flex: 1; }
    .header .h-menu_btn {
      position: static; }
  .h-logo {
    position: static; } }

@media (min-width: 642px) {
  .h-menu-info_mob {
    display: none; } }

@media (max-width: 641px) {
  .header {
    padding: 26px 24px; }
    .header .container {
      display: none; }
    .header .h-menu_btn {
      padding-top: 10px; }
    .header .h-menu .container {
      display: block; }
    .header .h-menu ul li a {
      font-size: 26px; }
  .h-logo {
    flex: 1; }
  .h-menu-info_mob {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 30px; } }

/*header*/
@media (max-width: 641px) {
  .video-back {
    display: none; } }

/*first*/
@media (min-width: 1401px) {
  .first {
    -webkit-background-size: cover;
    background-size: cover; } }

@media (max-width: 1400px) {
  .first {
    -webkit-background-size: auto 100%;
    background-size: auto 100%; } }

@media (max-width: 1249px) and (min-width: 1200px) {
  .first-block {
    background: url("../img/first-block.png") no-repeat center 90%, #FFFFFF;
    padding: 31px 20px;
    width: 218px;
    padding-bottom: 140px; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .first {
    padding-top: 154px; }
    .first-block {
      width: 702px;
      position: relative;
      top: 0;
      left: 0; }
    .first-bl {
      padding-top: 65px; } }

@media (max-width: 959px) and (min-width: 642px) {
  .first {
    padding-bottom: 217px;
    padding-top: 134px; }
    .first-block {
      width: 604px;
      position: relative;
      top: 0;
      left: 0; }
    .first-bl {
      padding-top: 42px; }
      .first-bl-txt {
        margin-bottom: 55px; } }

@media (max-width: 641px) {
  .first {
    padding-top: 114px;
    padding-bottom: 207px; }
    .first-block {
      width: 296px;
      position: relative;
      top: 0;
      left: 0; }
    .first-bl {
      padding-top: 36px; }
      .first-bl-ttl {
        margin-bottom: 34px; }
      .first-bl-txt {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 36px; } }

/*first*/
/*work*/
@media (max-width: 959px) and (min-width: 642px) {
  .work {
    padding-top: 96px; }
    .work-back {
      display: none; }
    .work-ttl {
      margin-bottom: 47px; }
    .work-power {
      margin-bottom: 44px;
      font-size: 36px;
      line-height: 100%; }
    .work-txt {
      margin-bottom: 54px; }
    .work-img {
      margin-bottom: 55px; }
    .work-txt2 {
      margin-bottom: 55px; }
    .work-info-bl {
      width: 100%;
      margin-bottom: 55px;
      padding: 0 36px; }
      .work-info-bl-img {
        margin-bottom: 29px; }
      .work-info-bl-txt {
        font-size: 18px;
        line-height: 23px; } }

@media (max-width: 641px) {
  .work {
    padding-top: 96px; }
    .work-back {
      display: none; }
    .work-ttl {
      margin-bottom: 47px;
      font-size: 22px;
      line-height: 29px; }
    .work-power {
      margin-bottom: 44px;
      font-size: 28px;
      line-height: 36px; }
    .work-txt {
      margin-bottom: 54px;
      font-size: 24px;
      line-height: 31px; }
    .work-img {
      margin-bottom: 55px; }
    .work-txt2 {
      margin-bottom: 55px; }
    .work-info-bl {
      width: 100%;
      margin-bottom: 55px;
      padding: 0 24px; }
      .work-info-bl-img {
        margin-bottom: 29px; }
      .work-info-bl-txt {
        font-size: 18px;
        line-height: 23px; } }

/*work*/
/*coef*/
@media (max-width: 959px) {
  .coef-col {
    width: 100% !important; } }

/*coef*/
/*goals*/
@media (max-width: 1400px) {
  .goals {
    -webkit-background-size: auto;
    background-size: auto; } }

@media (max-width: 1199px) and (min-width: 960px) {
  .goals {
    padding-top: 45px; }
    .goals-gallery-slide-txt {
      width: 592px; } }

@media (max-width: 959px) and (min-width: 642px) {
  .goals {
    padding-top: 3px; }
    .goals-back {
      display: none; }
    .goals-ttl {
      margin-bottom: 44px; }
    .goals-slider {
      padding-top: 120px; } }

@media (max-width: 641px) {
  .goals {
    padding-top: 3px;
    padding-bottom: 30px; }
    .goals-back {
      display: none; }
    .goals-ttl {
      margin-bottom: 44px; }
    .goals-slider {
      padding-top: 120px; }
    .goals-slide-bl {
      padding: 26px 24px; }
      .goals-slide-bl-num {
        font-size: 36px;
        line-height: 47px; }
      .goals-slide-bl-txt {
        font-size: 16px;
        line-height: 21px; }
    .goals-txt {
      font-size: 18px;
      line-height: 23px; }
    .goals-gallery-slide-img {
      border-radius: 6px 6px 0px 0px; }
    .goals-gallery-slide-txt {
      position: static;
      font-size: 16px;
      line-height: 21px;
      padding: 24px;
      width: 100%;
      border-radius: 0px 0px 6px 6px; }
    .goals-gallery .slick-arrow {
      width: 40px;
      height: 40px;
      margin-top: 0;
      top: 56px;
      left: -16px; }
      .goals-gallery .slick-arrow.arrow-next {
        right: -16px; } }

/*goals*/
/*serv*/
@media (max-width: 1400px) and (min-width: 1200px) {
  .serv-bl {
    width: calc(100% - 687px); } }

@media (max-width: 1199px) and (min-width: 960px) {
  .serv-bl {
    width: 49% !important; } }

@media (max-width: 959px) {
  .serv-back {
    display: none; }
  .serv-bl {
    width: 100% !important; }
  .serv-info-bl-row-block {
    width: 100% !important; } }

@media (max-width: 641px) {
  .serv {
    padding-top: 30px; }
    .serv-info-bl {
      padding-left: 0;
      padding-right: 0; } }

/*serv*/
/*compar*/
@media (max-width: 1400px) {
  .compar {
    -webkit-background-size: auto;
    background-size: auto; } }

@media (max-width: 641px) {
  .compar-tab {
    width: 100%;
    max-width: 372px;
    text-align: center;
    margin-right: 0 !important; } }

/*compar*/
/*info*/
@media (max-width: 959px) {
  .info-slide-bl-col {
    width: 100%;
    margin-bottom: 55px; }
    .info-slide-bl-col:last-child {
      margin-bottom: 0; } }

/*info*/
/*cf*/
@media (max-width: 959px) {
  .cf-wrap {
    padding: 60px; }
  .cf-back {
    display: none; } }

@media (max-width: 641px) {
  .cf-wrap {
    padding: 21px 20px;
    margin: 0 -24px; } }

/*cf*/
/*eco*/
@media (max-width: 959px) {
  .eco .back {
    background-size: 500px auto; }
  .eco-subttl {
    margin-top: 40px;
    font-weight: 600;
    font-size: 36px;
    line-height: 115%; }
  .eco-col {
    padding: 0 24px;
    width: 100%; } }

@media (max-width: 641px) {
  .eco .back {
    top: -90px;
    background-size: 280px auto; }
    .eco .back:before {
      display: none; } }

/*eco*/
/*contacts*/
@media (max-width: 1199px) and (min-width: 960px) {
  .contacts {
    padding-top: 254px;
    background-size: 100% auto;
    background-position: 0px -100px; } }

@media (max-width: 959px) {
  .contacts {
    padding-top: 254px;
    background-size: 100% auto;
    background-position: top center;
    background-image: url("../img/cont-back-mob.jpg"); }
    .contacts:before {
      display: none; }
    .contacts-wrap {
      padding: 60px 60px 14px; }
    .contacts-bl {
      padding: 0 24px;
      width: 100%;
      margin-bottom: 46px; } }

/*contacts*/
