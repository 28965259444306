// Fonts
$f_IBMPlexSans: 'IBMPlexSans', sans-serif;

/*IBMPlexSans-Regular*/
@font-face {
    font-family: 'IBMPlexSans';
    src: url('../fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.eot'),
    url('../fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.svg'),
    url('../fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.woff') format('woff'),
    url('../fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.ttf') format('truetype') {}
    font-weight: normal;
    font-style: normal; }
/*IBMPlexSans-Regular*/

/*IBMPlexSans-Medium*/
@font-face {
    font-family: 'IBMPlexSans';
    src: url('../fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.eot'),
    url('../fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.svg'),
    url('../fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.woff') format('woff'),
    url('../fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.ttf') format('truetype') {}
    font-weight: 500;
    font-style: normal; }
/*IBMPlexSans-Medium*/

/*IBMPlexSans-Bold*/
@font-face {
    font-family: 'IBMPlexSans';
    src: url('../fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.eot'),
    url('../fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.svg'),
    url('../fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.woff') format('woff'),
    url('../fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.ttf') format('truetype') {}
    font-weight: 700;
    font-style: normal; }
/*IBMPlexSans-Bold*/

/*IBMPlexSans-SemiBold*/
@font-face {
    font-family: 'IBMPlexSans';
    src: url('../fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.eot'),
    url('../fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.svg'),
    url('../fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.woff') format('woff'),
    url('../fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.ttf') format('truetype') {}
    font-weight: 600;
    font-style: normal; }
/*IBMPlexSans-SemiBold*/
