html,
body {
    font-size: 16px;
    color: $c_White;
    background: $c_main1;
    font-weight: 300;
    line-height: 1;
    font-family: $f_IBMPlexSans; }

main {
    overflow-x: hidden; }

$placeholderSelectors: '::-webkit-input-placeholder' '::-moz-placeholder' ':-ms-input-placeholder' ':-moz-placeholder';
@each $selector in $placeholderSelectors {
    *#{$selector} {
        @include transition(0.3s); } }

* {
    outline: 0; }

main {
    flex-grow: 1; }

svg, svg path, a, button {
    @include transition(0.3s); }


a:visited {
    color: inherit; }


img {
    display: inline-block;
    width: auto;
    max-width: 100%;
    height: auto; }

.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1170px;
    position: relative;
    z-index: 2; }

@media (min-width: 1200px) {
    .container {
        max-width: 942px; } }

@media (max-width: 1199px) and (min-width: 960px) {
    .container {
        max-width: 770px; } }

@media (max-width: 959px) and (min-width: 642px) {
    .container {
        max-width: 640px;
        padding: 0 36px; } }

@media (max-width: 641px) {
    .container {
        max-width: 100%;
        padding: 0 24px; } }

.row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap; }

@media (max-width: 959px) {
    .row {
        margin-left: -36px;
        margin-right: -36px; } }

@media (max-width: 641px) {
    .row {
        margin-left: -24px;
        margin-right: -24px; } }

.offset-col {
    padding: 0 15px;
    margin-right: -228px;
    margin-left: 230px;
    width: calc(100% + 230px);
    &.full {
        margin-left: 0!important; } }

@media(max-width: 1400px) {
    .offset-col {
        margin-right: calc((((100vw - 100% + 30px) / 2) - 36px) * -1);
        width: calc(100% + calc((((100vw - 100% + 30px) / 2) - 36px))); } }

@media (max-width: 1199px) and (min-width: 960px) {
    .offset-col {
        margin-left: 148px;
        width: calc(100% + 148px); } }

@media (max-width: 959px) {
    .offset-col {
        margin-left: 0;
        margin-right: 0;
        padding: 0 36px;
        width: 100%; } }

@media (max-width: 641px) {
    .offset-col {
        padding: 0 24px; } }

.ttl {
    font-weight: 600;
    font-size: 100px;
    line-height: 100%; }
@media (max-width: 1199px) {
    .ttl {
        font-size: 72px;
        line-height: 100%; } }
@media (max-width: 959px) {
    .ttl {
        font-size: 48px;
        line-height: 62px; } }
@media (max-width: 641px) {
    .ttl {
        font-size: 36px;
        line-height: 47px; } }

.subttl {
    font-weight: 600;
    font-size: 48px;
    line-height: 62px; }

@media (max-width: 959px) {
    .subttl {
        font-size: 36px;
        line-height: 47px; } }

@media (max-width: 641px) {
    .subttl {
        font-size: 28px;
        line-height: 36px; } }

.content-box {
    line-height: 1.4;
    h2 {
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 28px; }
    p {
        margin-bottom: 20px; }
    img {
        margin-bottom: 20px; }

    b, strong {
        font-weight: 700; }
    i, em {
        font-style: italic; }

    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px; }
    h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 15px; }
    ul {
        font-size: 20px;
        line-height: 140%;
        list-style: none;
        padding: 0;
        margin: 0;
        color: #323232;
        li {
            padding-left: 30px;
            margin-bottom: 22px;
            position: relative;
            &:before {
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: $c_main2;
                display: block;
                position: absolute;
                left: 0;
                top: 10px; } } }

    ol {
        list-style: decimal;
        padding-left: 20px;
        margin-bottom: 15px; }
    blockquote {
        border: dotted #666 1px;
        border-left: solid $c_main2 4px;
        margin: 10px 40px;
        padding: 15px;
        color: #333;
        font-style: italic;
        font-size: 14px;
        background: $c_White;
        .quote a {
            color: $c_main1;
            font-size: 12px; }

        .quote ul {
            list-style: none; } }

    table {
        thead {
            th {
                border-bottom: 2px solid $c_main2;
                padding: 3px 10px; } }
        tbody {
            td {
                border: 1px solid #ccc;
                padding: 3px 10px; } } } }



a {
    &:hover {
        color: $c_main2; } }

.btn {
    border: 2px solid $c_main2;
    display: inline-block;
    padding: 18px 37px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border-radius: 50px;
    background: none;
    color: $c_main1;
    text-align: center;
    position: relative;
    overflow: hidden;
    &.txt_white {
        color: $c_White; }
    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: $c_main2;
        display: block;
        transition: 0.3s; }
    span {
        position: relative;
        z-index: 2; }
    &:hover {
        color: $c_White;
        &:before {
            left: 0;
            transition: 0.3s; } } }

.slick-arrow {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $c_main2;
    cursor: pointer;
    z-index: 3;
    opacity: 1;
    transition: 0.3s;
    &.arrow-next {
        left: 80px; }
    &:hover {
        opacity: 0.7;
        transition: 0.3s; } }

@media (max-width: 641px) {
    .slick-arrow {
        &.arrow-next {
            left: auto;
            right: 0; } } }
