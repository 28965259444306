/*header*/
@media(max-width: 959px) {
    .header {
        display: flex;
        flex-wrap: wrap;
        .container {
            flex: 1; }

        .h-menu_btn {
            position: static; } }
    .h {
        &-logo {
            position: static; } } }

@media(min-width: 642px) {
    .h {
        &-menu-info_mob {
            display: none; } } }
@media(max-width: 641px) {
    .header {
        padding: 26px 24px;
        .container {
            display: none; }
        .h-menu_btn {
            padding-top: 10px; }
        .h-menu {
            .container {
                display: block; }
            ul {
                li {
                    a {
                        font-size: 26px; } } } } }
    .h {
        &-logo {
            flex: 1; }
        &-menu-info_mob {
            padding-top: 10px;
            display: flex;
            flex-wrap: wrap;
            margin: 20px 0 30px; } } }


/*header*/
@media(max-width: 641px) {
    .video-back {
        display: none; } }

/*first*/
@media(min-width: 1401px) {
    .first {
        -webkit-background-size: cover;
        background-size: cover; } }
@media(max-width: 1400px) {
    .first {
        -webkit-background-size: auto 100%;
        background-size: auto 100%; } }

@media(max-width: 1249px) and (min-width: 1200px) {
    .first {
        &-block {
            background: url('../img/first-block.png') no-repeat center 90%, $c_White;
            padding: 31px 20px;
            width: 218px;
            padding-bottom: 140px; } } }

@media (max-width: 1199px) and (min-width: 960px) {
    .first {
        padding-top: 154px;
        &-block {
            width: 702px;
            position: relative;
            top: 0;
            left: 0; }
        &-bl {
            padding-top: 65px; } } }

@media (max-width: 959px) and (min-width: 642px) {
    .first {
        padding-bottom: 217px;
        padding-top: 134px;
        &-block {
            width: 604px;
            position: relative;
            top: 0;
            left: 0; }
        &-bl {
            padding-top: 42px;
            &-txt {
                margin-bottom: 55px; } } } }

@media (max-width: 641px) {
    .first {
        padding-top: 114px;
        padding-bottom: 207px;
        &-block {
            width: 296px;
            position: relative;
            top: 0;
            left: 0; }
        &-bl {
            padding-top: 36px;
            &-ttl {
                margin-bottom: 34px; }
            &-txt {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 36px; } } } }

/*first*/

/*work*/
@media (max-width: 959px) and (min-width: 642px) {
    .work {
        padding-top: 96px;
        &-back {
            display: none; }
        &-ttl {
            margin-bottom: 47px; }
        &-power {
            margin-bottom: 44px;
            font-size: 36px;
            line-height: 100%; }
        &-txt {
            margin-bottom: 54px; }
        &-img {
            margin-bottom: 55px; }
        &-txt2 {
            margin-bottom: 55px; }
        &-info {
            &-bl {
                width: 100%;
                margin-bottom: 55px;
                padding: 0 36px;
                &-img {
                    margin-bottom: 29px; }
                &-txt {
                    font-size: 18px;
                    line-height: 23px; } } } } }
@media (max-width: 641px) {
    .work {
        padding-top: 96px;
        &-back {
            display: none; }
        &-ttl {
            margin-bottom: 47px;
            font-size: 22px;
            line-height: 29px; }
        &-power {
            margin-bottom: 44px;
            font-size: 28px;
            line-height: 36px; }
        &-txt {
            margin-bottom: 54px;
            font-size: 24px;
            line-height: 31px; }
        &-img {
            margin-bottom: 55px; }
        &-txt2 {
            margin-bottom: 55px; }
        &-info {
            &-bl {
                width: 100%;
                margin-bottom: 55px;
                padding: 0 24px;
                &-img {
                    margin-bottom: 29px; }
                &-txt {
                    font-size: 18px;
                    line-height: 23px; } } } } }
/*work*/

/*coef*/
@media (max-width: 959px) {
    .coef {
        &-col {
            width: 100% !important; } } }
/*coef*/

/*goals*/
@media (max-width: 1400px) {
    .goals {
        -webkit-background-size: auto;
        background-size: auto; } }
@media (max-width: 1199px) and (min-width: 960px) {
    .goals {
        padding-top: 45px;
        &-gallery {
            &-slide {
                &-txt {
                    width: 592px; } } } } }
@media (max-width: 959px) and (min-width: 642px) {
    .goals {
        padding-top: 3px;
        &-back {
            display: none; }
        &-ttl {
            margin-bottom: 44px; }
        &-slider {
            padding-top: 120px; } } }
@media (max-width: 641px) {
    .goals {
        &-back {
            display: none; }
        padding-top: 3px;
        padding-bottom: 30px;
        &-ttl {
            margin-bottom: 44px; }
        &-slider {
            padding-top: 120px; }
        &-slide {
            &-bl {
                padding: 26px 24px;
                &-num {
                    font-size: 36px;
                    line-height: 47px; }
                &-txt {
                    font-size: 16px;
                    line-height: 21px; } } }
        &-txt {
            font-size: 18px;
            line-height: 23px; }
        &-gallery {
            &-slide {
                &-img {
                    border-radius: 6px 6px 0px 0px; }
                &-txt {
                    position: static;
                    font-size: 16px;
                    line-height: 21px;
                    padding: 24px;
                    width: 100%;
                    border-radius: 0px 0px 6px 6px; } }
            .slick-arrow {
                width: 40px;
                height: 40px;
                margin-top: 0;
                top: 56px;
                left: -16px;
                &.arrow-next {
                    right: -16px; } } } } }

/*goals*/

/*serv*/
@media(max-width: 1400px) and (min-width: 1200px) {
    .serv {
        &-bl {
            width: calc(100% - 687px); } } }

@media (max-width: 1199px) and (min-width: 960px) {
    .serv {
        &-bl {
            width: 49%!important; } } }

@media (max-width: 959px) {
    .serv {
        &-back {
            display: none; }
        &-bl {
            width: 100%!important; }
        &-info {
            &-bl {
                &-row {
                    &-block {
                        width: 100%!important; } } } } } }
@media (max-width: 641px) {
    .serv {
        padding-top: 30px;
        &-info {
            &-bl {
                padding-left: 0;
                padding-right: 0; } } } }

/*serv*/

/*compar*/
@media (max-width: 1400px) {
    .compar {
        -webkit-background-size: auto;
        background-size: auto; } }
@media (max-width: 641px) {
    .compar {
        &-tab {
            width: 100%;
            max-width: 372px;
            text-align: center;
            margin-right: 0 !important; } } }

/*compar*/

/*info*/
@media (max-width: 959px) {
    .info {
        &-slide {
            &-bl {
                &-col {
                    width: 100%;
                    margin-bottom: 55px;
                    &:last-child {
                        margin-bottom: 0; } } } } } }

/*info*/

/*cf*/
@media (max-width: 959px) {
    .cf {
        &-wrap {
            padding: 60px; }
        &-back {
            display: none; } } }

@media (max-width: 641px) {
    .cf {
        &-wrap {
            padding: 21px 20px;
            margin: 0 -24px; } } }

/*cf*/

/*eco*/
@media (max-width: 959px) {
    .eco {
        .back {
            background-size: 500px auto; }
        &-subttl {
            margin-top: 40px;
            font-weight: 600;
            font-size: 36px;
            line-height: 115%; }
        &-col {
            padding: 0 24px;
            width: 100%; } } }

@media (max-width: 641px) {
    .eco {
        .back {
            top: -90px;
            background-size: 280px auto;
            &:before {
                 display: none; } } } }


/*eco*/

/*contacts*/
@media (max-width: 1199px) and (min-width: 960px) {
    .contacts {
        padding-top: 254px;
        background-size: 100% auto;
        background-position: 0px -100px; } }

@media (max-width: 959px) {
    .contacts {
        padding-top: 254px;
        background-size: 100% auto;
        background-position: top center;
        background-image: url('../img/cont-back-mob.jpg');
        &:before {
            display: none; }
        &-wrap {
            padding: 60px 60px 14px; }
        &-bl {
            padding: 0 24px;
            width: 100%;
            margin-bottom: 46px; } } }
/*contacts*/
