/*header*/
.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to bottom, rgba(7, 71, 83, 0.75), rgba(7, 71, 83, 0));
    display: block;
    z-index: 10;
    padding: 26px 36px;
    .container {
        position: relative;
        z-index: 2; } }

.h {
    &-logo {
        position: absolute;
        z-index: 3; }
    &-info {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap; }
    &-phone {
        margin-left: 40px; }
    &-lang {
        .lang {
            margin-left: 20px;
            &:first-child {
                margin-left: 0; }
            &.cur {
                font-weight: 600; } } }
    &-menu_btn {
        top: 36px;
        right: 36px;
        position: absolute;
        z-index: 3;
        display: block;
        button {
            background: none;
            border: none;
            width: 60px;
            cursor: pointer;
            padding: 0;
            span {
                width: 100%;
                height: 2px;
                background: #fff;
                margin: 11px 0;
                display: block;
                &:first-child {
                    margin-top: 0; }
                &:last-child {
                    margin-bottom: 0; } }
            &.active {
                span {
                    &:first-child {
                        transition: 0.3s;
                        transform: rotate(-25deg) translate(-5px, 14px); }
                    &:last-child {
                        transition: 0.3s;
                        transform: rotate(25deg) translate(-2px, -12px); }
                    &:nth-child(2) {
                        width: 0;
                        transition: 0.3s;
                        transform: translateX(50%); } } } } }
    &-menu {
        position: absolute;
        z-index: -9999;
        opacity: 0;
        width: 100%;
        left: 0;
        top: 0;
        padding-top: 100px;
        background: $c_White;
        pointer-events: none;
        transition: opacity 0.3s;
        &.active {
            z-index: 1;
            opacity: 1;
            transition: opacity 0.3s;
            pointer-events: all; }
        ul {
            li {
                margin-bottom: 36px;
                a {
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 120%;
                    color: $c_main1; } } } } }


.header.active {
    .h {
        &-logo {
            svg {
                path {
                    fill: $c_main1; } } }
        &-phone {
            a {
                color: $c_main1; } }
        &-lang {
            .lang {
                color: $c_main1; } }
        &-menu_btn {
            button {
                span {
                    background: $c_main1; } } } } }
/*header*/


.video-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    display: flex;
    justify-content: center; }

/*first*/
.first {
    padding-top: 139px;
    background: url('../img/first-back.png')no-repeat center top;
    padding-bottom: 164px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: calc(100% + 73px);
        top: 0;
        left: 0;
        z-index: 2;
        display: block;
        background: url('../img/tok_fon.gif') left bottom;
        background-repeat: repeat-x; }
    .container {
        position: relative;
        z-index: 3; }
    &-block {
        position: absolute;
        left: 0;
        box-shadow: 0px 10px 50px -10px rgba(7, 71, 83, 0.38);
        background: url('../img/first-block.png')no-repeat 100% 84%, #fff;
        width: 379px;
        padding: 31px 35px;
        padding-right: 135px;
        border-radius: 6px;
        top: 154px;
        z-index: 4;
        color: $c_main1;
        &-ttl {
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 27px; }
        &-more {
            a {
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: $c_main2; } } }
    &-bl {
        color: $c_main1;
        &-ttl {
            margin-bottom: 60px;
            color: $c_main1; }
        &-txt {
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 76px; } } }

/*first*/

/*work*/
.work {
    padding-top: 185px;
    padding-bottom: 70px;
    &-in {
        margin: 0 auto;
        position: relative;
        max-width: 1400px; }
    &-back {
        position: absolute;
        z-index: 1;
        background: url('../img/work-back.png')no-repeat center center;
        -webkit-background-size: 1312px 1312px;
        background-size: 1312px 1312px;
        width: 1312px;
        height: 1312px;
        display: block;
        &.back1 {
            background-image: url('../img/work-back.png');
            top: -100px;
            left: -314px; }
        &.back2 {
            background-image: url('../img/work-back2.png');
            top: 43px;
            right: -398px; }
        &.back3 {
            background-image: url('../img/work-back3.png');
            top: -436px;
            left: -353px; } }
    &-ttl {
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 65px; }
    &-power {
        font-weight: 600;
        font-size: 48px;
        line-height: 100%;
        margin-bottom: 63px; }
    &-txt {
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 74px; }
    &-img {
        overflow: hidden;
        border-radius: 6px;
        max-width: 912px;
        margin-bottom: 69px; }
    &-txt2 {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 75px; }
    &-info {
        &-bl {
            padding: 0 15px;
            width: 50%;
            margin-bottom: 72px;
            &-img {
                margin-bottom: 29px; }
            &-txt {
                font-size: 18px;
                line-height: 23px; } } } }

/*work*/

/*goal*/
.goals {
    padding-top: 57px;
    padding-bottom: 64px;
    background: url('../img/goal-back.jpg')no-repeat center top;
    -webkit-background-size: cover;
    background-size: cover;
    &-forsl {
        overflow: hidden; }
    &-ttl {
        margin-bottom: 66px; }
    &-slider {
        padding-top: 140px;
        max-width: 453px;
        margin-bottom: 68px;
        .slick-list.draggable {
            overflow: visible; } }
    &-slide {
        &-bl {
            background: $c_White;
            color: $c_main1;
            padding: 32px 37px;
            border-radius: 6px;
            margin-right: 4px;
            &-num {
                font-weight: 600;
                font-size: 48px;
                line-height: 62px;
                margin-bottom: 16px;
                color: $c_main2; }
            &-txt {
                font-size: 18px;
                line-height: 23px;
                overflow: hidden;
                height: 94px; } } }
    &-txt {
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 75px; }
    &-descr {
        margin-bottom: 56px; }
    &-subttl {
        margin-bottom: 66px; }
    &-gallery {
        &-bl {
            position: relative; }
        &-slide {
            &-img {
                position: relative;
                z-index: 1;
                overflow: hidden;
                border-radius: 6px; }
            &-txt {
                position: absolute;
                z-index: 2;
                bottom: 0;
                right: 0;
                padding: 36px;
                font-size: 18px;
                line-height: 23px;
                background: $c_main2;
                width: 684px;
                border-radius: 6px; } }
        .slick-arrow {
            top: 50%;
            margin-top: -30px;
            left: 36px;
            &.arrow-next {
                left: auto;
                right: 36px; } } }
    &-in {
        margin: 0 auto;
        position: relative;
        max-width: 1400px; }
    &-back {
        position: absolute;
        z-index: 1;
        background: url('../img/work-back.png')no-repeat center center;
        -webkit-background-size: 1312px 1312px;
        background-size: 1312px 1312px;
        width: 1312px;
        height: 1312px;
        display: block;
        &.back1 {
            background-image: url('../img/work-back.png');
            top: -483px;
            left: -314px; }
        &.back2 {
            background-image: url('../img/work-back2.png');
            top: -172px;
            right: -398px; } } }

/*goal*/

/*coef*/
.coef {
    padding-top: 160px;
    padding-bottom: 30px;
    &-ttl {
        margin-bottom: 56px; }
    &-txt {
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 73px; }
    &-row {
        margin: 0 -1px 79px; }
    &-col {
        margin-bottom: 3px;
        padding: 0 1px;
        width: 50%;
        &.full {
            width: 100%; } }
    &-bl {
        background: $c_White;
        color: $c_main1;
        padding: 36px 37px;
        border-radius: 6px;
        height: 100%;
        &-num {
            font-weight: 600;
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $c_main2;
            margin-bottom: 22px; }
        &-txt {
            font-size: 18px;
            line-height: 23px; } } }
/*coef*/

/*compar*/
.compar {
    padding-top: 28px;
    padding-bottom: 75px;
    overflow: hidden;
    background: url('../img/compar-back.jpg')no-repeat center top;
    -webkit-background-size: cover;
    background-size: cover;
    &-ttl {
        margin-bottom: 59px; }
    &-subttl {
        margin-bottom: 55px; }
    &-tabs {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 49px; }
    &-tab {
        border: 2px solid rgba(255, 255, 255, 0.3);
        display: inline-block;
        padding: 18px 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: .05em;
        text-transform: uppercase;
        border-radius: 50px;
        background: 0 0;
        margin-right: 22px;
        color: $c_main2;
        margin-bottom: 18px;
        &.active {
            color: $c_White;
            background: $c_main2;
            border-color: $c_main2; } }
    &-txt {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 54px; }
    &-txt2 {
        font-size: 24px;
        line-height: 31px; }
    &-slider {
        position: static;
        &_wrap {
            border-radius: 6px;
            background: $c_White;
            padding: 37px 37px 75px;
            position: relative;
            margin-bottom: 65px; }
        .slick-arrow {
            top: auto;
            bottom: 37px;
            width: auto;
            height: auto;
            background: $c_White;
            left: 35px;
            svg path {
                stroke: $c_main2; }
            &.arrow-next {
                left: 91px; } } }

    &-slide {
        &-bl {
            background: $c_White;
            color: $c_Black;
            &-num {
                font-weight: 600;
                font-size: 18px;
                line-height: 23px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: $c_main2;
                margin-bottom: 22px; }
            &-txt {
                font-size: 18px;
                line-height: 23px;
                min-height: 78px; } } }
    &-cont {
        &.hid {
            position: absolute;
            pointer-events: none;
            opacity: 0;
            visibility: hidden;
            z-index: -9999; } } }
/*compar*/

/*serv*/
.serv {
    padding-top: 43px;
    padding-bottom: 42px;
    &-in {
        margin: 0 auto;
        position: relative;
        max-width: 1400px; }
    &-back {
        position: absolute;
        z-index: 1;
        background: url('../img/work-back.png')no-repeat center center;
        -webkit-background-size: 1312px 1312px;
        background-size: 1312px 1312px;
        width: 1312px;
        height: 1312px;
        display: block;
        &.back1 {
            background-image: url('../img/work-back.png');
            top: -318px;
            left: -505px; }
        &.back2 {
            background-image: url('../img/work-back2.png');
            top: 175px;
            right: -527px; } }
    &-ttl {
        margin-bottom: 68px; }
    &-blocks {
        margin: 0 -1px 65px;
        display: flex;
        flex-wrap: wrap; }
    &-bl {
        border: 2px solid #6d8f8d;
        width: 455px;
        margin: 0px 1px 2px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 379px;
        border-radius: 6px;
        position: relative;
        overflow: hidden;
        background-size: cover;
        &-ttl {
            font-weight: 600;
            font-size: 24px;
            line-height: 31px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $c_White;
            position: relative;
            z-index: 2; }
        &-txt {
            color: $c_White;
            font-size: 18px;
            line-height: 23px;
            position: relative;
            z-index: 2; }
        &:nth-child(2), &:nth-child(3) {
            width: 683px; }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: $c_main1;
            opacity: 0.7; } }
    &-info {
        margin-top: 69px;
        border-top: 2px solid  rgba(255, 255, 255, 0.1);
        &-bl {
            padding-top: 31px;
            padding-left: 35px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding-right: 36px;
            padding-bottom: 42px;
            border-bottom: 2px solid rgba(255, 255, 255, 0.1);
            &-ttl {
                font-size: 24px;
                line-height: 31px;
                padding-right: 15px;
                flex: 1; }
            &-csl {
                button {
                    background: $c_main2;
                    border-radius: 50%;
                    border: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    transform: rotate(-45deg);
                    transition: 0.3s;
                    cursor: pointer;
                    &.active {
                        transform: rotate(0deg);
                        transition: 0.3s; } } }
            &-txt {
                padding-top: 29px;
                * {
                    color: $c_White;
                    font-size: 18px;
                    line-height: 23px;
                    margin-bottom: 23px;
                    &:last-child {
                        margin-bottom: 0; } } }
            &-row {
                margin: 0 -15px -42px;
                padding-top: 30px;
                display: flex;
                flex-wrap: wrap;
                &-block {
                    width: 50%;
                    padding: 0 15px;
                    margin-bottom: 42px;
                    &-num {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 23px;
                        color: $c_main2;
                        margin-bottom: 22px; }
                    &-txt {
                        color: $c_White;
                        font-size: 18px;
                        line-height: 23px; } } } } } }

/*serv*/

/*info*/
.info {
    padding-top: 38px;
    padding-bottom: 25px;
    overflow: hidden;
    &-slider {
        padding-top: 140px;
        overflow: visible;
        .slick-slide {
            margin-right: 10px; }
        .slick-list {
            overflow: visible; } }
    &-slide {
        &-bl {
            border-radius: 6px;
            background: $c_White;
            padding: 31px 37px;
            color: #021418;
            overflow: hidden;
            &-ttl {
                margin-bottom: 55px; }
            &-txt {
                font-size: 24px;
                line-height: 31px; }
            &-img {
                margin: 0 -37px -31px; }
            &-row {
                padding-top: 73px;
                display: flex;
                flex-wrap: wrap;
                margin: 0 -15px 0; }
            &-col {
                width: 50%;
                padding: 0 15px;
                margin-bottom: 20px;
                &-img {
                    margin-bottom: 29px; }
                &-txt {
                    font-size: 18px;
                    line-height: 23px; } }
            &-txt2 {
                font-size: 18px;
                line-height: 23px;
                margin-top: 46px;
                p {
                    margin-bottom: 23px; } }
            &-cls {
                margin-top: 75px;
                button {
                    background: none;
                    border: 0;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 23px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: $c_main2;
                    cursor: pointer;
                    .plus {
                        background: $c_main2;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        margin-left: 12px;
                        border-radius: 50%;
                        transition: 0.3s;
                        transform: rotate(-45deg); }
                    &.active {
                        .plus {
                            transition: 0.3s;
                            transform: rotate(0deg); } } } }
            &-img {
                margin-top: 67px; } } } }

/*info*/

/*cf*/
.cf {
    padding-top: 59px;
    padding-bottom: 50px;
    position: relative;
    z-index: 2;
    &-in {
        margin: 0 auto;
        position: relative;
        max-width: 1400px; }
    &-back {
        position: absolute;
        z-index: 1;
        background: url('../img/work-back.png')no-repeat center center;
        -webkit-background-size: 1312px 1312px;
        background-size: 1312px 1312px;
        width: 1312px;
        height: 1312px;
        display: block;
        &.back1 {
            background-image: url('../img/work-back.png');
            top: -213px;
            left: -325px;
            background-size: 885px 885px;
            width: 885px;
            height: 885px; }
        &.back2 {
            background-image: url('../img/work-back2.png');
            top: -175px;
            right: -427px; } }
    &-wrap {
        border-radius: 6px;
        background: url('../img/form-back.jpg')no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        padding: 80px 81px; }
    &-ttl {
        margin-bottom: 12px; }
    &-txt {
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 73px; }
    &-form {
        &-input {
            span {
                margin-bottom: 21px;
                display: block; }
            input {
                display: block;
                width: 100%;
                border-radius: 2px;
                padding: 18px 17px; }
            &-check {
                padding-top: 8px;
                max-width: 590px;
                .wpcf7-list-item-label {
                    cursor: pointer;
                    padding-left: 25px;
                    font-size: 14px;
                    margin-bottom: 43px;
                    display: inline-block;
                    position: relative;
                    letter-spacing: 0.05em;
                    line-height: 1.5;
                    &:before {
                        content: '';
                        display: block;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border: 2px solid $c_main2;
                        border-radius: 4px; }
                    a {
                        text-decoration: underline; } }
                input[type="checkbox"]:checked + .wpcf7-list-item-label:before {
                    background-color: $c_White;
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L3 7 1 4' stroke='%2339B54A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                    background-size: 6px 6px;
                    background-position: 2px 3px; } } }
        .wpcf7-not-valid-tip {
            background: #dc3232;
            color: #fff;
            padding: 3px 5px;
            display: inline-block; } } }

/*cf*/

/*eco*/
.eco {
    padding-top: 75px;
    position: relative;
    .back {
        position: absolute;
        z-index: 1;
        top: -370px;
        left: 0;
        background: url('../img/eco-back.png')no-repeat 0% 0%, $c_White;
        height: calc(100% + 372px);
        width: 100%;
        &:before {
            content: '';
            width: 100%;
            height: 50px;
            display: block;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 2;
            background: $c_main1;
            background: -webkit-linear-gradient(top,$c_main1,rgba(255,255,255,0));
            background: linear-gradient(to bottom,$c_main1,rgba(255,255,255,0)); } }
    &-ttl {
        color: $c_main1; }
    &-row {
        margin: 0 -1px 79px; }
    &-col {
        margin-bottom: 3px;
        padding: 0 1px;
        width: 50%;
        &.full {
            width: 100%; } }
    &-bl {
        background: $c_White;
        color: $c_main1;
        padding: 36px 37px;
        border-radius: 6px;
        height: 100%;
        border: 1px solid #E6E6E6;
        &-num {
            font-weight: 600;
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $c_main2;
            margin-bottom: 22px; }
        &-txt {
            font-size: 18px;
            line-height: 23px; } }
    &-subttl {
        margin-top: 127px;
        font-weight: 600;
        font-size: 48px;
        line-height: 115%;
        color: #021418; }
    &-txt {
        font-size: 24px;
        line-height: 31px;
        color: #021418;
        margin-top: 58px; }
    &-txt2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;
        color: #39B54A;
        margin-top: 62px;
        margin-bottom: 73px; } }

/*eco*/

/*contacts*/
.contacts {
    background: url('../img/cont-back.png') no-repeat 50% 0%;
    background-color: $c_main1;
    background-size: cover;
    padding-top: 554px;
    position: relative;
    &:before {
        content: '';
        width: 100%;
        height: 50px;
        display: block;
        top: 0px;
        left: 0;
        position: absolute;
        z-index: 2;
        background: $c_White;
        background: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0)); }

    &-wrap {
        padding: 81px 81px 11px;
        background: $c_White;
        border-radius: 4px 4px 0 0; }
    &-ttl {
        color: $c_main1;
        margin-bottom: 57px; }
    &-bl {
        width: 50%;
        padding: 0 15px;
        margin-bottom: 70px; }
    &-top {
        font-size: 18px;
        line-height: 23px;
        color: $c_main2;
        margin-bottom: 23px; }
    &-btm {
        font-size: 18px;
        line-height: 23px;
        color: #021418; } }


/*contacts*/
