[hidden] {
    display: none; }

$transition-duration: .25s;
$transition-delay: 0s;

@mixin transition($time) {
    -webkit-transition: all $time;
    -moz-transition: all $time;
    -ms-transition: all $time;
    -o-transition: all $time;
    transition: all $time; }
